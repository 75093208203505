* {
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
}

.quill .ql-editor {
  height: 85%;
  width: 100%;
}

.quill .ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #f7f7f7;
  border-color: #3472BD;
  font-size: 18px;
  
}

.ql-toolbar.ql-snow {
  display: block;
  background: rgb(52, 114, 189, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-toolbar.ql-snow{
  border-color: #3472BD;
  border-bottom-color: transparent;
}


p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0;
}